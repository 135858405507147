import React from 'react'
import Layout from '../components/layout-v2';
import Services from "../components/services";


const Page = () => (

  <Layout title="Cloud Migration">
    <section>


    </section>
  </Layout>
);

export default Page;
